@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ArcaMajora3 Bold';
  src: url('./assets/fonts/system/ArcaMajora3-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ArcaMajora3 Heavy';
  src: url('./assets/fonts/system/ArcaMajora3-Heavy.otf') format('opentype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/system/GillSans.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Louis George Cafe Bold Italic';
  src: url('./assets/fonts/system/Louis George Cafe Bold Italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Louis George Cafe Bold';
  src: url('./assets/fonts/system/Louis George Cafe Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Louis George Cafe';
  src: url('./assets/fonts/system/Louis George Cafe.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font-arcamajora-bold {
  font-family: 'ArcaMajora3 Bold', sans-serif;
}

.font-arcamajora-heavy {
  font-family: 'ArcaMajora3 Heavy', sans-serif;
}

.font-gill-sans {
  font-family: 'Gill Sans', sans-serif;
}

.font-louis-george-bold-italic {
  font-family: 'Louis George Cafe Bold Italic', sans-serif;
}

.font-louis-george-bold {
  font-family: 'Louis George Cafe Bold', sans-serif;
}

.font-louis-george {
  font-family: 'Louis George Cafe', sans-serif;
}

body {
  margin: 0;
  font-family: 'Arca Majora', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-arca-black {
  font-family: 'Arca Majora', sans-serif;
  font-weight: 900;
}

.font-arca-normal {
  font-family: 'Arca Majora', sans-serif;
  font-weight: 100;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loader-icon {
  display: inline-block;
  height: 6.4rem;
  width: 6.4rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: #ff9b00;
  position: relative;
  border-radius: 50%;
  margin-bottom: 2.5rem;
  -webkit-animation: rotate 0.3s linear infinite;
  animation: rotate 0.3s linear infinite;
}

.ql-toolbar.ql-snow {
  border: none !important;
  box-sizing: border-box;
  font-family: inherit !important;
  padding: 8px;
}
.ql-container.ql-snow {
  border: none !important;
}

.css-1fdsijx-ValueContainer {
  height: 45px;
}

@media (max-width: 640px) {
  .stepper .steps {
    flex-direction: column;
  }
  .stepper .steps .step {
    margin-bottom: 1rem;
  }
}
